<template>
  <div style="display: grid; gap: 1rem">
    <x-label>{{ $t('hotel.best-features') }}</x-label>
    <div style="overflow-x: auto">
      <carousel :data="hotelFeatures" gap="3" />
    </div>
  </div>
</template>

<script>
import HotelDetailsFeatureItem from './HotelDetailsFeatureItem.vue'

export default {
  name: 'HoteDetailsFeatures',
  props: { features: Array },
  computed: {
    hotelFeatures() {
      return this.features.map(feature => ({
        is: HotelDetailsFeatureItem,
        data: feature,
        class: [],
      }))
    },
  },
}
</script>

<style scoped lang="scss"></style>
