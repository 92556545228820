export const mapOptions = {
  streetViewControl: false,
  draggable: false,
  draggableCursor: 'pointer',
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  zoomControlOptions: {},
  styles: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels',
    },
    {
      featureType: 'poi',
    },
    {
      featureType: 'poi',
      elementType: 'labels.text',
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
    },
    {
      featureType: 'road.local',
      elementType: 'labels',
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      featureType: 'transit',
    },
  ].map(option => ({
    stylers: [{ visibility: 'off' }],
    ...option,
  })),
}
