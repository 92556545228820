<template>
  <div class="hotel-location">
    <XLabel>{{ $t('hotel.location') }}</XLabel>
    <a :href="locationHref" target="_blank">
      <GmapMap
        class="hotel-location-map"
        tabindex="-1"
        :center="location"
        :zoom="14"
        map-type-id="roadmap"
        :options="mapOptions"
      >
        <GmapMarker
          :position="location"
          :clickable="false"
          :draggable="false"
          :icon="{ url: require('../../../assets/marker-red.png') }"
        />
      </GmapMap>
    </a>
    <div v-if="hotel.address || hotel.phone" class="hotel-location-details">
      <template v-if="hotel.address">
        <IconPin />
        <a :href="locationHref" target="_blank">{{ hotel.address }}</a>
      </template>
      <template v-if="phone">
        <IconPhoneLarge />
        <a :href="`tel:${phone}`">{{ phone }}</a>
      </template>
    </div>
  </div>
</template>

<script>
import XLabel from '../../../components/common/global/XLabel.vue'
import { mapOptions } from '../hotels/HotelDetailsLocationMapOptions.js'
import IconPin from '../../icons/common/IconPin.vue'
import IconPhoneLarge from '../../icons/common/IconPhoneLarge.vue'

export default {
  name: 'HotelDetailsLocation',
  components: { XLabel, IconPin, IconPhoneLarge },
  props: { hotel: Object },
  data() {
    return { mapOptions }
  },
  computed: {
    phone() {
      return this.hotel.telephonesList[0].number
    },
    location() {
      const { latitude, longitude } = this.hotel.coordinates
      return { lat: +latitude, lng: +longitude }
    },
    locationHref() {
      // return `https://www.google.com/maps/search/?api=1&query=${this.data.position.lat},${this.data.position.lng}`
      return `https://www.google.com/maps/search/?api=1&query=${this.hotel.name},${this.hotel.address}`.replaceAll(
        ' ',
        '+',
      )
    },
  },
}
</script>

<style lang="scss">
.hotel-location {
  display: grid;
  gap: 0.75rem;
}
.hotel-location-map {
  overflow: hidden;
  height: 200px;
  width: 100%;
  border-radius: var(--border-radius-m);
  background: var(--color-ternary);
}
.hotel-location-details {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: inherit;
  align-items: center;
  & > *:nth-child(2n-1) {
    justify-self: center;
  }
  & > a {
    font-size: 13px;
  }
}
</style>
