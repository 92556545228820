<template>
  <div class="hotel-description">
    <p
      style="font-family: DDin; font-size: 18px"
      :class="!readMoreShown && 'hotel-description-read-more-hidden'"
    >
      {{ description }}
    </p>
    <p
      class="hotel-description-read-more-button"
      @click="readMoreShown = !readMoreShown"
    >
      {{ readMoreShown ? $t('hotel.hide') : $t('hotel.read-more') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'HotelDetailsDescription',
  props: { description: String },
  data() {
    return { readMoreShown: false }
  },
}
</script>

<style scoped lang="scss">
.hotel-description {
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.3px;
}
.hotel-description-read-more-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.hotel-description-read-more-button {
  color: var(--color-grey);
  margin-top: 0.5rem;
  cursor: pointer;
  transition: var(--transition-default);
  font-size: 0.825rem;
  &:hover {
    color: darker(get('color.grey'), 20%);
  }
}
</style>
