<template>
  <div class="feature-item" @click="$emit('click')">
    <div class="feature-item-image-wrapper">
      <img
        :src="data.imageUrl"
        alt="feature"
        height="50"
        width="50"
        class="feature-item-image"
      />
    </div>
    <p class="feature-item-text">
      {{ data.name }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FeatureDetailsFeatureItem',
  props: { data: { type: Object } },
}
</script>

<style scoped lang="scss">
.feature-item {
  width: 100px;
  padding: 2px;
}
.feature-item-image-wrapper {
  border-radius: 100%;
  border: 1px solid #535353;
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature-item-image {
  object-fit: contain;
  object-position: center;
}
.feature-item-text {
  text-align: center;
  margin-top: 0.5rem;
  font-size: 11px;
  line-height: 1.2;
  color: #212121;
  word-break: break-word;
  hyphens: auto;
}
</style>
