<template>
  <div class="hotel-amenities">
    <XLabel>{{ $t('hotel.top-amenities') }}</XLabel>
    <div class="hotel-amenity">
      <template v-for="amenity in shownAmenities">
        <p>{{ amenity.name }}</p>
        <img
          :src="amenity.imageUrl"
          width="28"
          height="28"
          alt="Amenity"
          class="hotel-amenities-image"
        />
      </template>
    </div>
    <button
      v-if="amenities.length > 2"
      class="hotel-amenities-button"
      @click="showAll = !showAll"
    >
      {{ showAll ? $t('hotel.hide') : 'SEE MORE' }}
    </button>
  </div>
</template>

<script>
import XLabel from '../../../components/common/global/XLabel.vue'
export default {
  name: 'HotelDetailsAmenities',
  data() {
    return { showAll: false }
  },
  props: { amenities: Array },
  computed: {
    shownAmenities() {
      return this.showAll || this.amenities.length < 3
        ? this.amenities
        : this.amenities.slice(0, 2)
    },
  },
  components: { XLabel },
}
</script>

<style scoped lang="scss">
.hotel-amenities {
  display: grid;
  gap: 0.75rem;
}
.hotel-amenity {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
  & > p {
    font-size: 13px;
    font-family: SpaceMono;
  }
}
.hotel-amenities-button {
  color: var(--color-grey);
  text-align: start;
  cursor: pointer;
  transition: var(--transition-default);
  font-size: 13px;
  &:hover {
    color: darker(get('color.grey'), 20%);
  }
}
</style>
