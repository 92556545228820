<template>
  <div class="container-xs" style="max-width: 550px">
    <AppBackHeader to="/search/hotels">{{ hotel.name }}</AppBackHeader>
    <div class="hotel-image-wrapper">
      <div v-if="hotelImages.length">
        <Swiper
          :update-key="hotelImages.length"
          use-navigation
          style="margin-top: -0.5rem"
        >
          <img
            v-for="image in hotelImages"
            :src="image"
            alt="hotel"
            class="swiper-slide hotel-image"
          />
        </Swiper>
      </div>
      <div v-else class="hotel-image" data-placeholder>
        <IconPlaceholderHotelDark
          style="color: var(--color-icon)"
          width="100%"
          height="150"
        />
      </div>
    </div>
    <div style="display: grid; gap: 0.75rem">
      <template v-if="hotel.description">
        <HotelDetailsDescription :description="hotel.description" />
        <AppDashedLine />
      </template>
      <template v-if="amenities.length">
        <HotelDetailsFeatures :features="amenities.slice(0, 5)" />
        <AppDashedLine />
        <HotelDetailsAmenities :amenities="amenities" />
        <AppDashedLine />
      </template>
      <HotelDetailsLocation :hotel="hotel" />
      <RoomCard
        style="margin-top: 1rem"
        :room="room"
        :hotel="hotel"
        change-button
        show-info
        info-label="Change room type"
        data-selected="true"
        @click="$router.push(`/search/hotel/rooms`)"
      />
      <XButton
        type="large"
        style="margin-top: 1rem"
        @click="onSelect(hotel.id)"
        >{{ $t('search.confirm') }}</XButton
      >
    </div>
    <LoadingModalFullScreen
      ref="hotelLoadingModal"
      non-closable
      no-fade-in
      text="LOADING HOTEL DETAILS"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import AppDashedLine from '../../../components-rf/AppDashedLine.vue'
import IconPlaceholderHotelDark from '../../../components-rf/icons/placeholders/IconPlaceholderHotelDark.vue'
import LoadingModalFullScreen from '../../../components-rf/modals/LoadingModalFullScreen.vue'
import HotelDetailsAmenities from '../../../components-rf/search/hotels/HotelDetailsAmenities.vue'
import HotelDetailsDescription from '../../../components-rf/search/hotels/HotelDetailsDescription.vue'
import HotelDetailsFeatures from '../../../components-rf/search/hotels/HotelDetailsFeatures.vue'
import HotelDetailsLocation from '../../../components-rf/search/hotels/HotelDetailsLocation.vue'
import RoomCard from '../../../components-rf/search/room/RoomCard.vue'
import XButton from '../../../components/common/global/XButton.vue'
import Swiper from '../../../components/utils/Swiper.vue'

export default {
  name: 'TripHotelDetails',
  components: {
    AppBackHeader,
    XButton,
    HotelDetailsDescription,
    HotelDetailsFeatures,
    HotelDetailsAmenities,
    HotelDetailsLocation,
    RoomCard,
    LoadingModalFullScreen,
    AppDashedLine,
    IconPlaceholderHotelDark,
    Swiper,
  },
  mounted() {
    const { hotelSelection } = this.selection
    const modal = this.$refs.hotelLoadingModal
    if (!hotelSelection.hasDetails) {
      modal.show()
      hotelSelection.getDetails().finally(modal.hide)
    }
  },
  computed: {
    ...mapState('SearchState', ['hotelId']),
    ...mapGetters('SearchState', ['getActive', 'getSelected']),
    selection: vm => vm.getSelected({}),
    hotel: vm => vm.selection.hotel,
    room: vm => vm.selection.room,
    amenities: vm => vm.selection.hotel.amenitiesList,
    hotelImages: vm => vm.hotel.mediaList.filter(image => image),
  },
  methods: {
    ...mapActions('SearchState', ['select']),
    onSelect(hotelId) {
      this.select({ hotelId, confirm: true })
      this.$router.push('/search/summary')
    },
  },
}
</script>

<style scoped lang="scss">
.hotel-image-wrapper {
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  margin-left: -1rem;
  margin-right: -1rem;
  @include s {
    margin-left: initial;
    margin-right: initial;
  }
}
.hotel-image {
  width: 100%;
  height: 250px;
}
.hotel-image[data-slider] {
  @include s {
    height: 300px;
  }
}
.hotel-image[data-placeholder] {
  background: var(--color-icon-background);
  display: flex;
  align-items: center;
  padding: 2rem;
}
</style>
